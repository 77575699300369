@font-face {
  font-family: fim;
  src: url(../public/fim.woff);
}

body {
  margin: 0;
  font-family: "Helvetica Neue", "Montserrat", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html,
#root {
  height: 100%;
  overflow-x: hidden;
}

.fim-icons {
  background-color: red;
}

.fim-icons::before {
}
